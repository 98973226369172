import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  // 路由配置
  {
    path: '/index',
    redirect: '/',
  },
  {
    path:'/',
    name: 'Index',
    component: () => 
        import('../views/Index.vue'),
  },
  {
    path:'/mergeland',
    name: 'Mergeland',
    component: () => 
        import('../views/Mergeland.vue'),
  },
  {
    path:'/secret',
    name: 'Secret',
    component: () => 
        import('../views/Secret.vue'),
  },
  {
    path:'/mergeCove',
    name: 'MergeCove',
    component: () => 
        import('../views/MergeCove.vue'),
  },
  {
    path:'/bubble',
    name: 'Bubble',
    component: () => 
        import('../views/Bubble.vue'),
  },
  {
    path:'/sudoku',
    name: 'Sudoku',
    component: () => 
        import('../views/Sudoku.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  // 其他配置
});

export default router;