import Vue from 'vue';
import App from './App.vue';
import router from './router'; // 导入路由配置文件
import store from './store'; // 导入 Vuex store
import ElementUI from 'element-ui'; // 导入 Element UI
import 'element-ui/lib/theme-chalk/index.css'; // 导入 Element UI 样式
import axios from 'axios'; // 导入 Axios
import "swiper/css/swiper.min.css"
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';


import VueI18n from 'vue-i18n';
import ZH from './locales/zh';
import HK from './locales/hk';
import EN from './locales/en';

Vue.use(ElementUI); // 使用 Element UI
Vue.prototype.$axios = axios; // 将 Axios 挂载到 Vue 实例的原型上，以便全局使用
Vue.config.productionTip = false;
Vue.use(VueI18n);

Vue.component('Swiper', Swiper);
Vue.component('SwiperSlide', SwiperSlide);

// 创建 VueI18n 实例
const i18n = new VueI18n({
  // 从 Cookie 中读取语言设置，如果不存在则使用中文作为默认语言
  locale: getSavedLanguage() || 'zh',
  messages: {
    zh: ZH,
    hk: HK,
    en: EN,
  },
});
// 在应用程序初始化时读取 Cookie 中的语言设置
function getSavedLanguage() {
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name.trim() === 'language') {
      return value;
    }
  }
  return null;
}

new Vue({
  router, // 使用路由配置
  store, // 使用 Vuex store
  i18n,
  render: (h) => h(App),
}).$mount('#app');