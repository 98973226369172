export default {
  index: {
    lang: 'zh',

    menu1: '首页',
    menu2: '游戏产品',
    menu3: '关于我们',
    menu4: '企业生活',

    tips: '游戏健康忠告：抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。',
    number1: '京公网安备11010502054233',
    number2: '京ICP备2023035689号-1',
    copyright: '索洛幻想 版权所有 Copyright @ 2021-2023 Discovery Games, All Rights Reserved.',

    title1: '游戏产品',

    title2: '关于我们',
    info21: '创造出',
    info22: '让玩家快乐、让自己自豪的游戏',
    info23: '索洛幻想专注于全球化精品手游，并不断探索创新领域，业务覆盖超140个国家和地区，开拓及深耕欧洲、北美、日韩、中东、东南亚等市场。',
    info24: '企业文化',
    info25: '热爱',
    info26: '简单',
    info27: '正念',
    info28: '极端',
    info29: '谦卑',

    title3: '企业生活',
    info31: '办公环境',
    info32: '索洛幻想在北京、济南均设有办公室，简洁明亮的室内环境，给员工更好的工作体验。',
    info33: '欢乐活动',
    info34: '生日庆祝、节日活动、兴趣俱乐部，丰富多彩的公司活动，一起度过快乐时光！',
    info35: '员工福利',
    info36: '补充商业保险、福利假期、企业优惠等贴心到位的福利项目，健康工作、享受生活。',
  },

  Mergeland: {
    text1: '以经典童话故事《爱丽丝奇境历险记》和《爱丽丝穿镜奇幻记》为原型改编的游戏剧情，再现了神秘仙境的经典场景和人物。',
    text12: '在这里，你可以跟随小女孩Ally的脚步，再次穿越探访奇妙仙境，拯救白兔、疯帽子等人物，恢复仙境的原本面貌。',
    text2: '游戏亮点',
    text3: '充满神奇魔力的童话世界观，从清新的原野、神秘的森林，到宏伟壮观的皇宫，在我们艺术家的设计下，一个个经典的童话场景展现在玩家眼前，无处不在的精美细节带来前所未有的视觉盛宴。',
    text4: '合并玩法和场景修复玩法的融合，让玩家合并出的每个元素都可作为修复仙境的素材，随着元素等级的提高，整个破损的仙境也逐渐恢复原貌展现给玩家。',
    text5: '丰富多样的活动和节日副本令玩家不仅可以体验到《灰姑娘》、《睡美人》等经典童话，还能通过完成活动挑战获取角色/修复物的皮肤，按照个人喜好装扮属于自己的独特仙境。',
  },

  Secret: {
    text1: 'Taylor为了寻找消失的母亲，开始了一段跌宕起伏的冒险，甜蜜的恋爱、神秘的黑猫传说、埋藏于Loveland20多年的秘密等待揭晓...在这座充满秘密和阴谋的小岛上，帮助Taylor揭开母亲消失的真相吧！',
    text2: '游戏亮点',
    text3: '简单易学且有趣的融合游戏，多元化的物品收集体验。',
    text4: '随着剧情的推进和资源的收集，玩家可以选择不同的建筑风格，从头开始设计和建造整个岛屿。还可以通过参加限时活动，获取特有的场景装饰物品。',
    text5: '不间断的赛事玩法，丰富的活动奖励，让你有更加爽快的游戏体验。',
  },

  Mergecove: {
    text1: '在都市中失意的艾莉回到家乡，却没想到昔日那仙境般美丽的小镇已被灾难摧毁。与祖母爱丽丝一起重振小镇吧，揭开小镇的秘密，让她恢复往昔的美丽！踏入这里，一同开始崭新的旅程！',
    text2: '游戏亮点',
    text3: '时尚现代化的游戏场景，让玩家通过合成元素享受修复小镇过程的同时，还可以切换获取人物和场景皮肤，装扮属于自己的特色小镇。',
    text4: '玩家还能通过活动获得和收集的收藏卡，跟随主角一起了解主角的祖母年轻时穿越仙境的奇妙故事。',
  },

  Bubble: {
    text1: '享受最轻松有趣的泡泡射击游戏，上千个引人入胜的关卡和令人惊叹的泡泡图形，匹配、射击和爆破气泡，沉浸在一个充满五彩泡泡等待被击落的缤纷世界。',
    text2: '游戏亮点',
    text3: '超多谜题关卡，丰富的玩法和活动奖励供玩家探索发现，体验更多玩法乐趣',
    text4: '色彩鲜艳的泡泡和多样的增/减益元素，享受丰富的视觉效果同时，还可以拯救被泡泡困住的可爱动物。',
  },

  Sudoku: {
    text1: '一款令人上瘾的数字益智游戏，不仅可以随时随地享受数独游戏，还能从中学习数独技巧。',
    text2: '游戏亮点',
    text3: '与世界各地的玩家同场竞技，提升段位挑战更高难度的数独游戏。',
    text4: '丰富的主题活动、独创的IQ游戏，让玩家在收集各种活动明信片的同时，体验更多元的大脑训练题目。',
  },

}